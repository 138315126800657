import React from 'react'
import PropTypes from 'prop-types'
import Card from '@atoms/card'

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  footer: PropTypes.node,
  header: PropTypes.node,
}

const MetricCard = ({ header, children, footer, ...rest }) => {
  return (
    <Card {...rest}>
      <Card.Header>{header}</Card.Header>
      <Card.Body>{children}</Card.Body>
      <Card.Footer>{footer}</Card.Footer>
    </Card>
  )
}

MetricCard.propTypes = propTypes
export default MetricCard
