import React from 'react'
import PropTypes from 'prop-types'
import Card from '@atoms/card'
import Heading from '@particles/heading'
import Text from '@particles/text'
import ServiceLogo from '@atoms/service-logo'

const propTypes = {
  subtitle: PropTypes.string,
  src: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string
}

const ServiceCard = ({ subtitle, src, name, id, ...rest }) => {
  return (
    <Card {...rest} hover>
      <Card.Center>
        <ServiceLogo sm name={name} src={src} id={id} />
        <Heading h4 style={{ marginTop: '1rem' }}>
          {name}
        </Heading>
        <Text>{subtitle}</Text>
      </Card.Center>
    </Card>
  )
}

ServiceCard.propTypes = propTypes
export default ServiceCard
