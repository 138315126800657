import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Text from '../../particles/text'

const propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  change: PropTypes.func,
  relativePath: PropTypes.string
}

const PaginationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledPaginationContainer = styled.div`
  margin: 0em 1em;
  a, .anchor {
    display: flex;
    text-decoration: none;
    pointer-events: ${(props) => (props.active ? 'none' : '')};
    padding: 0.1875em 0.5em;
    background: ${(props) =>
      props.active ? props.theme.palette.primary['100'] : ''};
    margin-bottom: 1rem;
    cursor: pointer;

    transition: background 150ms ease-out;
    :hover {
      background: ${(props) => props.theme.colors.surface};
    }
    :active {
      background: ${(props) => props.theme.colors.secondary};
    }
  }

  ${Text} {
    color: #000000;
  }
`

const PaginationLink = ({ children, active, to, click, ...rest }) => {
  return (
    <StyledPaginationContainer active={active}>
    {!click ?
      <Link to={to} {...rest}>
        <Text as="span" bold>
          {children}
        </Text>
      </Link>
      :
      <div className={"anchor"}>
        <Text as="span" bold onClick={click}>
          {children}
        </Text>
      </div>
    }
      
    </StyledPaginationContainer>
  )
}

PaginationLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  to: PropTypes.string,
  click: PropTypes.func,
  active: PropTypes.bool
}

const Pagination = ({
  currentPage = 1,
  totalPages = 10,
  change = null,
  relativePath = 'relative_path'
}) => {
  let min, max
  const linksToDisplay = 5
  const nextPage = currentPage + 1
  const prevPage = currentPage - 1

  const calculateDisplayedNumbers = () => {
    const temp = []
    if (totalPages >= 5) {
      max = currentPage + 2
      min = currentPage - 3
      if (min <= 0) {
        min = 0
        max = 5
      }
      if (max >= totalPages) {
        max = totalPages
        min = totalPages - 5
      }
      for (let i = 1; i <= linksToDisplay; i++) {
        temp[i] = min + i
      }
    } else {
      for (let i = 1; i <= totalPages; i++) {
        temp[i] = i
      }
    } // [2,3,5,6,7]
    return temp
  }

  const displayedNumbers = calculateDisplayedNumbers()
  

  if (totalPages <= 0) {
    console.error("Can't render pagination of 0 pages")
    return <div />
  }

  return (
    <PaginationContainer>
      {currentPage !== 1 && currentPage !== 2 && (
        <PaginationLink
          to={relativePath}
          click={change ? () => {
            change(1)
          } : null}
        >
          First
        </PaginationLink>
      )}
      {currentPage !== 1 && (
        <PaginationLink
          to={prevPage === 1 ? relativePath : `${relativePath}/${prevPage}`}
          click={change ? () => {
            change(prevPage)
          }: null}
        >
          Previous
        </PaginationLink>
      )}

      {displayedNumbers.map((v, i) => {
        return (
          <PaginationLink
            key={`pagination-${i}`}
            to={v === 1 ? relativePath : `${relativePath}/${v}`}
            active={v === currentPage}
            click={change ? () => {
              change(v)
            }: null}
          >
            {v}
          </PaginationLink>
        )
      })}

      {currentPage !== totalPages && (
        <PaginationLink
          to={`${relativePath}/${nextPage}`}
          click={change ? () => {
            change(nextPage)
          }: null}
        >
          Next
        </PaginationLink>
      )}
      {currentPage !== totalPages && currentPage !== totalPages - 1 && (
        <PaginationLink
          to={`${relativePath}/${totalPages}`}
          click={change ? () => {
            change(totalPages)
          }: null}
        >
          Last
        </PaginationLink>
      )}
    </PaginationContainer>
  )
}

Pagination.propTypes = propTypes
export default Pagination
