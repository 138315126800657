import React from 'react'
import PropTypes from 'prop-types'
import Card from '@atoms/card'

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  header: PropTypes.node,
  footer: PropTypes.node
}

const ListCard = ({ header, children, footer, ...rest }) => {
  return (
    <Card width="475px" list="true" {...rest}>
      <Card.Header>{header}</Card.Header>
      <Card.Body>
        <Card.List>
          {/* <Card.List.Item logo={<ServiceLogo facebook xs/>}><Text color={"200"}>Facebook</Text> <Text color={"200"}>20 Metrics</Text></Card.List.Item> */}
          {children}
        </Card.List>
      </Card.Body>
      <Card.Footer>
        {footer}
        {/* <Link to="TEST">SEE ALL METRICS <IconArrowRight/></Link> */}
      </Card.Footer>
    </Card>
  )
}

ListCard.propTypes = propTypes
ListCard.Item = Card.List.Item
export default ListCard
