import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import Heading from '@particles/heading'
import { Link as GatsbyLink } from 'gatsby'
import Link from '@atoms/link'
import Icon from '@icons'
import { marginZero, media } from '@breakpoints'
import { Defaults } from '@styles/theme'

const CardHeader = styled(Heading).attrs((props) => {
  props.level = 'h4'
})`
  margin-bottom: 1rem;
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
`

const CardList = styled.ol`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 -1.5rem;
  list-style: none;
  padding: 0;
`

const CardFooter = styled.div`
  display: flex;
  margin-top: 1rem;

  ${Link} {
    color: ${(props) => props.theme.palette.text['200']};
    display: flex;
    justify-content: space-between;
    width: 100%;

    ${Icon} {
      fill: ${(props) => props.theme.palette.text['200']};
    }

    :hover {
      color: ${(props) => props.theme.palette.primary['300']};
      ${Icon} {
        fill: ${(props) => props.theme.palette.primary['300']};
      }
    }
  }
`

const CardCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
`

const ListLink = styled(GatsbyLink)`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
`

const CardListItemComponent = ({
  children,
  className,
  logo,
  action = () => {},
  link,
  ...rest
}) => {
  if (link) {
    return (
      <li className={className} {...rest} onClick={action}>
        <ListLink to={link}>
          {logo}
          <div>{children}</div>
        </ListLink>
      </li>
    )
  } else {
    return (
      <li className={className} {...rest} onClick={action}>
        {logo}
        <div>{children}</div>
      </li>
    )
  }
}

CardListItemComponent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  logo: PropTypes.node,
  action: PropTypes.func,
  link: PropTypes.string
}

const CardListItem = styled(CardListItemComponent)`
  display: flex;
  align-items: center;
  height: 4rem;
  width: 100%;
  padding: 0 1.5rem;
  transition: background 150ms ease-out;
  cursor: pointer;

  :hover {
    background: ${(props) => props.theme.palette.background['200']};
  }

  div:last-child {
    flex: 1;
    margin-left: ${(props) => (props.logo != undefined ? '0.5rem' : undefined)};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const CardComponent = ({ children, className, action, link, ...rest }) => {
  return link ? (
    <Link className={className} {...rest} onClick={action} to={link}>
      {children}
    </Link>
  ) : (
    <div className={className} {...rest} onClick={action}>
      {children}
    </div>
  )
}

CardComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  action: PropTypes.func,
  link: PropTypes.string
}

const Card = styled(CardComponent)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: white;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: ${Defaults.borderRadius};
  min-height: ${(props) => (props.list ? 'auto' : '229px')};
  width: ${(props) => (props.auto ? undefined : props.width || '300px')};
  padding: 1.5em;
  margin: ${(props) => marginZero(props)};

  ${(props) => props.link && css`
    transition: all 200ms ease;
    :hover {
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);
    }
  `}

  ${media.phablet`
    width: 100%;
  `}
`

Card.Body = CardBody
Card.Header = CardHeader
Card.Footer = CardFooter
Card.Center = CardCenter
Card.List = CardList
Card.List.Item = CardListItem
export default Card
