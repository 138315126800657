import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Label from '@atoms/label'
import Text from '@particles/text'
import Button from '@atoms/button'
import Icon from '@icons'
import { useComponentVisible } from '@particles/hooks'
import { Defaults } from '@styles/theme'

const propTypes = {}

const ListItem = styled(Label)`
  width: auto;
  margin: 0.5rem 0 0.5rem 0;
  line-height: 1.5;
  font-size: 0.9rem;
  font-weight: 500;
`

const CollapsedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: ${({ sticky }) => (sticky ? 'sticky' : 'static')};
  top: ${({ sticky }) => sticky || 0};
  z-index: 20;

  ${Button} {
    width: 100%;
    justify-content: center;
  }

  ul {
    overflow: scroll;

    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;

    background: white;
    border-radius: ${Defaults.borderRadius};
    margin: 0 !important;
    margin-top: 0.5rem !important;
  }

  ul.list-collapsed {
    height: 0;
    opacity: 0;
  }

  ul.list-shown {
    height: ${({ sticky }) => `calc(100vh - ${sticky || '0px'} - 100px)`};
    border: 1px solid #d7dcea;
    padding: 0.5rem 0;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
    opacity: 1;
  }

  ${Icon} {
    transition: transform 0.4s ease-in-out;
  }
  ${Icon}.flip {
    transform: rotateX(180deg);
  }
`

const ListComponent = ({
  className,
  title,
  data,
  data_numbers,
  children,
  sticky,
  collapsed = false,
  ...rest
}) => {
  if (data !== undefined && data.length > 0 && data[0] instanceof Object) {
    data_numbers = []
    data = data.map((v, i) => {
      data_numbers[i] = v.number
      return v.data
    })
  }

  const {
    ref: resultRef,
    isComponentVisible: isResultVisible,
    setIsComponentVisible: setIsResultVisible
  } = useComponentVisible(false)

  return collapsed ? (
    <CollapsedContainer ref={resultRef} sticky={sticky}>
      <Button
        onClick={() => {
          setIsResultVisible(!isResultVisible)
        }}
      >
        {title}{' '}
        <Icon
          className={isResultVisible ? 'flip' : undefined}
          name="chevron-down"
          margin="L1"
          size={1}
          color="#fff"
        />
      </Button>
      <ul
        className={`${className} ${
          isResultVisible ? 'list-shown' : 'list-collapsed'
        }`}
        {...rest}
      >
        {data &&
          data.map((v, i) => {
            return (
              <ListItem
                key={`${v}-${i}`}
                as="li"
                suffix={data_numbers ? data_numbers[i] : undefined}
              >
                {v}
              </ListItem>
            )
          })}
        {children}
      </ul>
    </CollapsedContainer>
  ) : (
    <ul className={className} {...rest}>
      <Text bold size={1.25} color="300" padding="0.75 1">
        {title}
      </Text>
      {data &&
        data.map((v, i) => {
          return (
            <ListItem
              key={`${v}-${i}`}
              passAs="li"
              suffix={data_numbers ? data_numbers[i] : undefined}
            >
              {v}
            </ListItem>
          )
        })}
      {children}
    </ul>
  )
}

ListComponent.propTypes = {
  data: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  title: PropTypes.string,
  data_numbers: PropTypes.array,
  sticky: PropTypes.bool,
  collapsed: PropTypes.bool
}

const List = styled(ListComponent)`
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;
`

List.propTypes = propTypes
List.Item = ListItem
export default List
