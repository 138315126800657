import React from 'react'

import Layout from '@organisms/layout'
import Heading from '@particles/heading'
import Cards from '@molecules/cards'
import List from '@molecules/list'
import Pagination from '@atoms/pagination'

const MetricPage = () => (
  <Layout>
    <Heading h1 bottom={1}>
      All metrics
    </Heading>
    <Heading h4 as="h2" width="550px">
      Learn more about the metrics that matter the most to your business success
    </Heading>
    <Layout.Grid margin="T4.375" gridTemplate="1fr 4.3fr" gap={3.75}>
      <List data={['All', 'Dude']} data_numbers={[1, 2]} title="Catergories" />
      <Layout.Grid fixed={278} gap={1.25}>
        <Cards.Metric header="Ad Click" auto>
          Ad Click
        </Cards.Metric>
        <Cards.Metric header="Ad Click" auto>
          Ad Click
        </Cards.Metric>
        <Cards.Metric header="Ad Click" auto>
          Ad Click
        </Cards.Metric>
      </Layout.Grid>
    </Layout.Grid>
    <Layout.Grid margin="T4.375" gridTemplate="1fr 4.3fr" gap={3.75}>
      <span />
      <Pagination currentPage={1} totalPages={4} />
    </Layout.Grid>
  </Layout>
)
export default MetricPage
